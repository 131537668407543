import { Input } from "@mui/material";
import React, { useState } from "react";

export const SmallInput = (props) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(props.helperText);
  const soloNumeros = (e) => {
    const valor = e.target.value;
    const esNumerico = /^\d+$/.test(valor);
    if (!esNumerico) return false;

    const { max, min } = props;
    if (max !== undefined && min !== undefined) {
      return valor <= max && valor >= min;
    }
    return true;
  };

  const soloTelefono = (e) => {
    return e.target.value.length > 0 && /^(\+\d{1,18})?$|^\d{1,18}$/.test(e.target.value);
  };

  const fecha = (e) => {
    if (e.target.value.length > 0) {
      const separados = e.target.value.split("-");
      if (separados[0].length === 4 && separados[1].length === 2 && separados[2].length === 2) {
        return true;
      }
    } else {
      return true;
    }
  };

  const soloNumerosDecimales = (e) => {
    return String(e.target.value).match(/^[+-]?\d+(\.\d+)?$/);
  };

  const email = (e) => {
    return String(e.target.value).match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const alfanumerico = (e) => {
    if (typeof e.target.value === "string" && e.target.value.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const sentenceCase = (e) => {
    const cursorStart = e.target.selectionStart;
    const cursorEnd = e.target.selectionEnd;
    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.setSelectionRange(cursorStart, cursorEnd);
    return e.target.value;
  };

  const validarRestricciones = (e) => {
    const valueInput = e.target.value;

    let todoValido = true;
    if (props.restricciones?.includes("soloNumeros") && !soloNumeros(e)) {
      valueInput = "";
      const { max, min } = props;
      if (max !== undefined && min !== undefined) {
        setHelperText(`El valor debe ser menor o igual que ${props.max}`);
      } else {
        setHelperText("¡Sólo números!");
      }
      todoValido = false;
    }

    if (props.restricciones?.includes("soloTelefono") && !soloTelefono(e)) {
      valueInput = "";
      setHelperText(`El numero no es valido`);
      todoValido = false;
    }

    if (props.restricciones?.includes("soloNumerosDecimales") && !soloNumerosDecimales(e)) {
      setHelperText("¡Sólo números decimales!");
      todoValido = false;
    }

    if (props.restricciones?.includes("email") && !email(e)) {
      setHelperText("¡Ingresa un email válido!");
      todoValido = false;
    }

    if (props.restricciones?.includes("alfanumerico") && !alfanumerico(e)) {
      setHelperText("¡Sólo letras y números!");
      todoValido = false;
    }
    if (props.restricciones?.includes("fecha") && !fecha(e)) {
      setHelperText("¡Sólo se permite fecha!");
      todoValido = false;
    }
    if (todoValido) {
      setError(false);
      setHelperText(props.helperText);
      if (props.seterror) {
        props.seterror(false);
      }
    } else {
      setError(true);
      if (props.seterror) {
        props.seterror(true);
      }
    }
    if (isNaN(valueInput) && !props.restricciones?.includes("alfanumerico") && !props.restricciones?.includes("email") && !props.restricciones?.includes("fecha")) {
      props.onChange(sentenceCase(e), props.name);
    } else {
      props.onChange(valueInput, props.name);
    }
  };

  return (
    <Input
      label={props.label}
      value={props.value}
      onChange={(e) => validarRestricciones(e)}
      fullWidth
      autoComplete="off"
      accept={props.accept}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      style={props.style}
      required={props.required}
      type={props.type}
      error={error || props.error}
      multiline={props.multiline}
    />
  );
};
