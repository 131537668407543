/* eslint-disable no-unused-vars */
import { CancelOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import { Grid, ListItemText, Switch, Typography } from "@mui/material";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { alone, getEstado } from "../../../store/slices/estado";
import { ButtonIcon, Divider, Input } from "../../atoms/";

const ListaDeAtributosEstado = ({ onSubmit, editting, isEmpresa }) => {
  const estado = useSelector(getEstado, shallowEqual);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");

  const handleCancel = () => {
    let redirect;
    if (isEmpresa) {
      if (!id) {
        redirect = `${PRIVATE_ROUTES.LIST_STATES_EMPRESAS}`;
      }
      redirect = `${PRIVATE_ROUTES.FICHA_STATE_EMPRESAS}?id=${id}`;
    } else {
      if (!id) {
        redirect = `${PRIVATE_ROUTES.LIST_STATES}`;
      }
      redirect = `${PRIVATE_ROUTES.FICHA_STATE}?id=${id}`;
    }
    navigate(redirect);
  };

  const changeValue = (value, key) => {
    dispatch(alone({ [key]: value }));
  };

  const handleSwitchChange = (event) => {
    changeValue(event.target.checked, "elige_empresa");
  };

  const handleSave = () => {
    onSubmit();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} md={6}>
          <Input label="Nombre" icon={<PersonIcon />} value={estado?.nombre ? estado?.nombre : ""} name="nombre" onChange={changeValue} type="text" restricciones={["alfanumerico"]} required disabled={!editting} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItemText sx={{ flex: "none", paddingRight: "10px" }} primary={<Typography style={{ fontWeight: 700 }}>Elige empresa:</Typography>} />
          <Switch disabled={!editting} checked={estado?.elige_empresa} onChange={handleSwitchChange} inputProps={{ "aria-label": "controlled" }} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {editting && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonIcon type="button" variant="contained" color="error" noLink handleClick={handleCancel} startIcon={<CancelOutlined />}>
              Cancelar
            </ButtonIcon>
            <ButtonIcon variant="contained" color="success" noLink startIcon={<CheckIcon />} handleClick={handleSave} styleButton={{ marginLeft: "10px" }}>
              Guardar
            </ButtonIcon>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default ListaDeAtributosEstado;
