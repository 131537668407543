import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getSuceso } from "../../../store/slices/sucesos";
import { ListImages } from "../ListImages";
import { style } from "./styles";

const ListInformeComuna = () => {
  const suceso = useSelector(getSuceso, shallowEqual);
  return (
    <List sx={style} aria-label="mailbox folders">
      <ListItem>
        <ListItemText sx={{ display: "flex", justifyContent: "center", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700, fontSize: "1.2rem" }}>Informe Comuna</Typography>} />
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Domicilio Real:</Typography>} />
        <ListItemText>
          {suceso?.datos_comuna?.calle} {suceso?.datos_comuna?.chapa} ({suceso?.datos_comuna?.referencia})
        </ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}> Especie Arbol:</Typography>} />
        <ListItemText>{suceso?.datos_comuna?.especie_arbol}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}> DAP (cm):</Typography>} />
        <ListItemText>{suceso?.datos_comuna?.dap}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Altura (mts):</Typography>} />
        <ListItemText>{suceso?.datos_comuna?.altura}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Daño:</Typography>} />
        <ListItemText>{suceso?.datos_comuna?.tipoDanio}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Incidencia:</Typography>} />
        <ListItemText>{suceso?.datos_comuna?.tipoIncidencia}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Estado:</Typography>} />
        <ListItemText>{suceso?.estado}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Corte de calle:</Typography>} />
        <ListItemText>{suceso?.datos_comuna?.corte_de_calle}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Observaciones:</Typography>} />
        <ListItemText>{suceso?.datos_comuna?.observaciones}</ListItemText>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText sx={{ flex: "none", paddingRight: "5px" }} primary={<Typography style={{ fontWeight: 700 }}>Fotos de comuna:</Typography>} />
        <ListImages imagenes={suceso?.datos_comuna?.fotos_comuna} ruta="datos_comuna.fotos_comuna" handleChange={() => {}} editable={false} />
      </ListItem>
      <Divider component="li" />
    </List>
  );
};
export default ListInformeComuna;
