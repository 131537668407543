import { tiendasApi } from "../api";

export const TipoEspecieArbolApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTiposEspecieArbol: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/tipoespeciearbol?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    createTipoEspecieArbol: builder.mutation({
      query: (body) => ({
        url: "/tipoespeciearbol",
        method: "POST",
        body: body,
      }),
    }),
    updateTipoEspecieArbol: builder.mutation({
      query(element) {
        const { id, datos } = element;
        return {
          url: `/tipoespeciearbol/${id}`,
          method: "PUT",
          body: datos,
        };
      },
    }),
    deleteTipoEspecieArbol: builder.mutation({
      query: (id) => ({
        url: `/tipoespeciearbol/${id}`,
        method: "DELETE",
      }),
    }),
    getTipoEspecieArbol: builder.mutation({
      query: (id) => ({
        url: "/tipoespeciearbol/" + id,
        method: "GET",
      }),
    }),
    uploadPartial: builder.mutation({
      query: ({ valor, campo, id }) => {
        return {
          url: `/suceso/updatePartial/${id}`,
          method: "PUT",
          body: { valor, campo },
        };
      },
    }),
    getTipoEspecieCodigo: builder.mutation({
      query: (codigo) => {
        return {
          url: `/tipoespeciearbol/codigo/${codigo}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useCreateTipoEspecieArbolMutation,
  useGetTipoEspecieCodigoMutation,
  useUploadPartialMutation,
  useDeleteTipoEspecieArbolMutation,
  useGetTipoEspecieArbolMutation,
  useUpdateTipoEspecieArbolMutation,
  useGetTiposEspecieArbolMutation,
} = TipoEspecieArbolApiSlice;
