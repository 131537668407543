import { Container } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../routes/name-path.routes";
import { useGetEmpresasMutation } from "../../../store/api/endpoints/empresaApiSlice";
import { useGetStatesMutation } from "../../../store/api/endpoints/stateApiSlice";
import { useDeleteSucesoMutation, useGetSucesoMutation, useUpdateSucesoMutation } from "../../../store/api/endpoints/sucesosApiSlice";
import { useGetTipoCortesMutation } from "../../../store/api/endpoints/tipocortesApiSlice";
import { useGetTipoDaniosMutation } from "../../../store/api/endpoints/tipoDanioApiSlice";
import { useGetTipoEspecieCodigoMutation, useGetTiposEspecieArbolMutation } from "../../../store/api/endpoints/tipoEspecieArbolApiSlice";
import { useGetTiposIncidenteMutation } from "../../../store/api/endpoints/tipoIncidenteApiSlice";
import { useGetTipoNombreCalleCodigoMutation, useGetTipoNombreCallesMutation } from "../../../store/api/endpoints/tipoNombreCalleApiSlice";
import { setList } from "../../../store/slices/empresa";
import { ListEstados } from "../../../store/slices/estado";
import { alone, aloneComuna } from "../../../store/slices/sucesos";
import { list as listCortes } from "../../../store/slices/tipoCortes";
import { listDanio } from "../../../store/slices/tipoDanio";
import { listEspecies } from "../../../store/slices/tipoEspecieArbol";
import { listIncidentes } from "../../../store/slices/tipoIncidentes";
import { listNombres } from "../../../store/slices/tipoNombreCalle";
import { Loading, Volver } from "../../molecule";
import { HeaderVista } from "../../organism";
import ListaDeAtributosSuceso from "../../organism/suceso/FormSuceso";
import ListInformeGeneral from "../../organism/suceso/ListInformGeneral";

const SucesoEditTemplate = () => {
  const dispatch = useDispatch();
  const [suceso, setSuceso] = useState([]);
  const [editting, seteditting] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [deleteSuceso, responseDelete] = useDeleteSucesoMutation();
  const [updateSuceso, responseUpdate] = useUpdateSucesoMutation();
  const [getSucesoAlone, responseSuceso] = useGetSucesoMutation();
  const [getCortes, responseCortes] = useGetTipoCortesMutation();
  const [getEstados, responseStates] = useGetStatesMutation();
  const [getIncidencias, responseIncidencias] = useGetTiposIncidenteMutation();
  const [getDanios, responseDanios] = useGetTipoDaniosMutation();
  const [getEspecieArbol, responseEspecieArbol] = useGetTiposEspecieArbolMutation();
  const [getCalles, responseCalles] = useGetTipoNombreCallesMutation();
  const [getSingleCalle, responseSingleCalle] = useGetTipoNombreCalleCodigoMutation();
  const [getSingleEspecie, responseSingleEspecie] = useGetTipoEspecieCodigoMutation();
  const [getEmpresas, responseEmpresas] = useGetEmpresasMutation();

  const id = searchParams.get("id");

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      let sucesoFormatted = JSON.parse(JSON.stringify(responseSuceso.data.data));
      let splitFecha = sucesoFormatted?.fechahora.split("T");
      sucesoFormatted.fecha = splitFecha[0];
      sucesoFormatted.hora = splitFecha[1];
      getSingleCalle({ codigo: sucesoFormatted?.datos_comuna?.calle });
      getSingleEspecie(sucesoFormatted?.datos_comuna?.especie_arbol);
      getEmpresas({ page: 0, limit: 50, comunas: sucesoFormatted?.comuna });

      if (sucesoFormatted.datos_comuna && sucesoFormatted.datos_comuna.calle) {
        delete sucesoFormatted.datos_comuna.calle;
        delete sucesoFormatted.datos_comuna.especie_arbol;
      }
      dispatch(alone(sucesoFormatted));
    }
  }, [responseSuceso.isSuccess]);

  useEffect(() => {
    if (responseCortes.isSuccess) {
      dispatch(listCortes(responseCortes?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseStates.isSuccess) {
      dispatch(ListEstados(responseStates?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre, elige_empresa: el?.elige_empresa }))));
    }
    if (responseIncidencias.isSuccess) {
      dispatch(listIncidentes(responseIncidencias?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseDanios.isSuccess) {
      dispatch(listDanio(responseDanios?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseEspecieArbol.isSuccess) {
      dispatch(listEspecies(responseEspecieArbol?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseCalles.isSuccess) {
      dispatch(listNombres(responseCalles?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseEmpresas.isSuccess) {
      dispatch(setList(responseCalles?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
  }, [responseCortes.isSuccess, responseStates.isSuccess, responseIncidencias.isSuccess, responseEmpresas.isSuccess, responseDanios.isSuccess, responseEspecieArbol.isSuccess, responseCalles.isSuccess]);

  useEffect(() => {
    if (responseSingleCalle.isSuccess) {
      const valor = responseSingleCalle?.data?.data?.nombre ?? "";
      dispatch(aloneComuna({ calle: valor }));
    }
    if (responseSingleEspecie.isSuccess) {
      const valor = responseSingleEspecie?.data?.data?.nombre ?? "";
      dispatch(aloneComuna({ especie_arbol: valor }));
    }
  }, [responseSingleCalle.isSuccess, responseSingleEspecie.isSuccess]);

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        getCortes({ page: 0, limit: 50 }),
        getEstados({ page: 0, limit: 50 }),
        getIncidencias({ page: 0, limit: 50 }),
        getDanios({ page: 0, limit: 50 }),
        getEspecieArbol({ page: 0, limit: 50 }),
        getSucesoAlone({ id, isFicha: false }),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id, getCortes, getEstados, getIncidencias, getDanios, getEspecieArbol, getSucesoAlone]);

  useEffect(() => {
    fetchData();
  }, []);

  if (responseSuceso.isLoading || responseUpdate.isLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" to={`${PRIVATE_ROUTES.FICHA_SUCESOS}?id=${id}`} />} titulo={"Ficha del suceso"} />
      <ListInformeGeneral />
      <ListaDeAtributosSuceso />
      {/*    <ListadoInformeEmpresa /> */}
      <br />
    </Container>
  );
};

export default SucesoEditTemplate;
