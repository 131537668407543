import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

export default function CheckboxList(props) {
  const handleToggle = (value) => () => {
    const currentIndex = props?.elegidos?.indexOf(value);
    const newChecked = currentIndex === -1 ? [...props?.elegidos, value] : props?.elegidos?.filter((item) => item !== value);
    props.handleChange(newChecked, props.name);
  };

  return (
    <>
      <h4 align="center">{props.titulo}</h4>

      <div>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {props?.opciones.map((value) => {
            const labelId = `checkbox-list-label-${value.codigo}`;
            return (
              <ListItem key={value.codigo} disablePadding>
                <ListItemButton role={undefined} onClick={handleToggle(value.codigo)} dense disabled={props.disabled}>
                  <ListItemIcon>
                    <Checkbox edge="start" checked={props.elegidos?.indexOf(value.codigo) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.nombre} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
    </>
  );
}
